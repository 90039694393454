import API from "../../Api/Api.js";
import { handleError, handleInfo } from "./notifications.js";
import qs from "qs";
import { pageQueryOnlyLocale, pageQueryAll } from "./Queries.js";
const token = localStorage.getItem("token");

export const getPagesData = () => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  dispatch({ type: "PAGES_LOADING" });
  try {
    let { data } = await API.get("api/pages?" + pageQueryOnlyLocale);

    dispatch({ type: "FETCH_PAGES_PAGE", payload: data.data });
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const getPageByName = (name) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;
  const query = qs.stringify(
    {
      filters: {
        name: {
          $eq: name,
        },
      },
      populate: {
        sections: {
          populate: {
            image: {
              populate: "*",
            },
            image2: { populate: ["*"] },

            list: { populate: { cards: { populate: ["image", "image2"] } } },
          },

          sort: ["order:asc"],
        },
        seo: {
          populate: "ogimage",
        },
      },
    },

    {
      encodeValuesOnly: true,
    }
  );

  dispatch({ type: "PAGES_LOADING" });
  try {
    let { data } = await API.get("api/pages?" + query);

    dispatch({ type: "FETCH_HOME_PAGE", payload: data.data[0] });
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));

    console.log(error);
  }
};

export const getPageItem = (id) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  dispatch({ type: "PAGES_LOADING" });
  try {
    let { data } = await API.get(`api/pages/${id}?` + pageQueryAll);

    dispatch({ type: "FETCH_ITEM_PAGE", payload: data.data });
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));

    console.log(error);
  }
};

export const realTimeInsertData = (page) => async (dispatch) => {
  try {
    dispatch({ type: "INSERT_DATA", payload: page });
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));

    console.log(error);
  }
};

export const updateSuppSections = (id, data) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  dispatch({ type: "PAGES_SUPP_LOADING", payload: true });

  try {
    await API.put(`api/pages/${id}`, { data: data });

    const page = await API.get(`api/pages/${id}?${pageQueryAll}`);
    dispatch(realTimeInsertData(page.data.data));
    dispatch({ type: "PAGES_SUPP_LOADING", payload: false });
    dispatch(handleInfo("Page mise à jour", 1));
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};
