import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "../../Components/Common/Loading";
import InputText from "../../Components/sectioninput/formelement/InputText";
import { getContactItem, setContactViewed } from "../../REDUX/actions/contact";
import moment from "moment";
import { contactType } from "../../utils/utils";
import CustomButton from "../../Components/Common/CustomButton";
import ENDPOINT from "../../REDUX/CONSTS/ENDPOINT";

const ContactItemView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const { contactLoading, contactItem } = useSelector(
    (state) => state.contactsReducer
  );
  const FRONT_SITE_URL = "https://cabinet-taboni.fr/offre/";
  useEffect(() => {
    dispatch(getContactItem(params.id));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (contactItem) {
      if (!contactItem.attributes.viewedBy.includes(user.firstName)) {
        dispatch(
          setContactViewed(
            contactItem.id,
            `${contactItem.attributes.viewedBy}, ${
              user.firstName
            } le ${moment().format("MM-DD-YYYY")}`
          )
        );
      }
    }
    // eslint-disable-next-line
  }, [contactItem]);
  return contactLoading || !contactItem ? (
    <Loading />
  ) : (
    <div className='container-fluid bg-white shadowed p-5 rounded-10 '>
      <div className='row f24 align-items-center mb-2'>Donnée du client</div>

      <div className='row'>
        <div className='col-6'>
          <InputText
            labelinput={"Objet de la demande"}
            readOnly
            value={contactType(contactItem).text}
            flag={false}
          />
          <InputText
            labelinput={"Prénom"}
            readOnly
            value={contactItem.attributes.firstName}
            flag={false}
          />
          <InputText
            labelinput={"Nom"}
            readOnly
            value={contactItem.attributes.lastName}
            flag={false}
          />
          <InputText
            labelinput={"E-mail"}
            readOnly
            value={contactItem.attributes.email}
            flag={false}
          />
          <InputText
            labelinput={"Téléphone"}
            readOnly
            value={contactItem.attributes.phoneNumber}
            flag={false}
          />
        </div>
        <div className='col-6'>
          {contactItem.attributes.message ? (
            <>
              <div className='f16 text-start'>Message</div>
              <p className='text-start mt-2 styleInput p-2 rounded-3'>
                {contactItem.attributes.message}
              </p>
            </>
          ) : null}
          {contactItem.attributes.offerTitle ? (
            <>
              <div className='f16 text-start'>titre de bien</div>
              <p className='text-start mt-2 styleInput p-2 rounded-3'>
                {contactItem.attributes.offerTitle}
              </p>
            </>
          ) : null}
          {contactItem.attributes.label &&
          contactItem.attributes.serviceType === 2 ? (
            <>
              <div className='f16 text-start'>copropriété</div>
              <p className='text-start mt-2 styleInput p-2 rounded-3'>
                {contactItem.attributes.label}
              </p>
            </>
          ) : null}
          {contactItem.attributes.offerLink ? (
            <a
              href={`${FRONT_SITE_URL}${contactItem.attributes.offerTitle}`}
              rel='noreferrer'
              target='_blank'
            >
              <CustomButton buttonText={"Voir le lien de l'offre"} />
            </a>
          ) : null}
          {contactItem.attributes.CVFile.data ? (
            <a
              href={`${ENDPOINT}${contactItem.attributes.CVFile?.data?.attributes?.url}`}
              rel='noreferrer'
              target='_blank'
            >
              <CustomButton buttonText={"Voir le CV"} />
            </a>
          ) : null}
        </div>
        {contactItem.attributes.propertyAddress ||
        contactItem.attributes.currentTrustee ||
        contactItem.attributes.parcellesCount ||
        contactItem.attributes.propertyType ||
        contactItem.attributes.surface ||
        contactItem.attributes.description ||
        contactItem.attributes.details ? (
          <>
            <div className='row f24 align-items-center mb-2 mt-5'>
              Donnée de la propriété
            </div>
            <div className='row'>
              <div className='col-6'>
                {contactItem.attributes.propertyAddress ? (
                  <InputText
                    labelinput={"Adresse"}
                    readOnly
                    value={contactItem.attributes.propertyAddress}
                    flag={false}
                  />
                ) : null}
                {contactItem.attributes.currentTrustee ? (
                  <InputText
                    labelinput={"Syndic Actuel"}
                    readOnly
                    value={contactItem.attributes.currentTrustee}
                    flag={false}
                  />
                ) : null}
                {contactItem.attributes.parcellesCount ? (
                  <InputText
                    labelinput={"Nombre de lots principaux"}
                    readOnly
                    value={contactItem.attributes.parcellesCount}
                    flag={false}
                  />
                ) : null}
                {contactItem.attributes.propertyType ? (
                  <InputText
                    labelinput={"Type de la propriété"}
                    readOnly
                    value={contactItem.attributes.propertyType}
                    flag={false}
                  />
                ) : null}
                {contactItem.attributes.surface ? (
                  <InputText
                    labelinput={"Surface"}
                    readOnly
                    value={contactItem.attributes.surface}
                    flag={false}
                  />
                ) : null}
              </div>

              <div className='col-6'>
                {contactItem.attributes.description ? (
                  <>
                    <div className='f16 text-start'>
                      Description de la propriété
                    </div>
                    <p className='text-start mt-2 styleInput p-2 rounded-3'>
                      {contactItem.attributes.description}
                    </p>
                  </>
                ) : null}
                {contactItem.attributes.details ? (
                  <>
                    <div className='f16 text-start'>Plus de détails</div>
                    <p className='text-start mt-2 styleInput p-2 rounded-3'>
                      {contactItem.attributes.details}
                    </p>
                  </>
                ) : null}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ContactItemView;
