import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Check } from "../../REDUX/actions/users";
import { useLocation } from "react-router-dom";

const AuthCheck = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  // const { userLoading, user } = useSelector((state) => state.usersReducer);

  useEffect(() => {
    if (!location.pathname.includes("login")) {
      dispatch(Check());
    } // eslint-disable-next-line
  }, [location.pathname]);

  return <>{children}</>;
};

export default AuthCheck;
