import React from "react";
import SectionInput from "../sectioninput/SectionInput";
import "./ArticleEventForm.css";
import SectionPanel from "./SectionPanel";

const ArticleEventForm = ({ eventItemForm }) => {
  return (
    <div className="container-fluid mt-5">
      <div className="row  justify-content-center align-items-start">
        <div className=" col-12 col-md-8">
          {!eventItemForm.attributes.sections.data.length ? (
            <div className=" bg-white rounded-10 p-5 noArticleBox bold f24 text-grey">Pour commencer, ajouter une section depuis votre boite à outils</div>
          ) : (
            <div>
              {eventItemForm.attributes.sections.data.map((section, index) => (
                <div className="mb-4" key={index}>
                  <SectionInput titleOrange={`Section ${index + 1}`} sectionData={section.attributes} sectionId={section.id} deleteButton eventItemForm={eventItemForm} noBtn />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className=" col-12 col-md-4 ">
          <SectionPanel eventItemForm={eventItemForm} />
        </div>
      </div>
    </div>
  );
};

export default ArticleEventForm;
