import React from "react";
import { Routes as Switch, Route } from "react-router-dom";
import TestView from "../Views/testView/TestView";
import LoginView from "../Views/LoginView/LoginView";
import DashboardView from "../Views/DashboardView/DashboardView";
import BlogView from "../Views/BlogView/BlogView";
import PagesView from "../Views/PagesView/PagesView";
import ContactView from "../Views/ContactView/ContactView";
import FilesView from "../Views/FilesView/FilesView";
import UsersView from "../Views/UsersView/UsersView";
import AccountView from "../Views/AccountView/AccountView";

import ArticleItemView from "../Views/ArticleItemView/ArticleItemView";
import { Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import AdminRoutes from "./AdminRoutes";
import NewUserView from "../Views/NewUserView/NewUserView";
import ContactItemView from "../Views/ContactItemView/ContactItemView";
import PageItemView from "../Views/PageItemView/PageItemView";

const Routes = () => {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const token = localStorage.getItem("token");
  return (
    <Switch>
      {/* ---------------------------------- restricted Paths ------------------------------------- */}

      <>
        <Route
          path="/"
          exact
          element={
            <ProtectedRoute isLoggedIn={user.id}>
              <DashboardView />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/blog"
          exact
          element={
            <ProtectedRoute isLoggedIn={user.id}>
              <BlogView />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/blog/:id"
          exact
          element={
            <ProtectedRoute isLoggedIn={user.id}>
              <ArticleItemView />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/pages/:id"
          exact
          element={
            <ProtectedRoute isLoggedIn={user.id}>
              <PageItemView />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/pages"
          exact
          element={
            <ProtectedRoute isLoggedIn={user.id}>
              <PagesView />
            </ProtectedRoute>
          }
        ></Route>
        {/* <Route
          path="/products"
          exact
          element={
            <ProtectedRoute isLoggedIn={user.id}>
              <ProductsView />
            </ProtectedRoute>
          }
        ></Route> */}
        {/* <Route
          path="/products/:id"
          exact
          element={
            <ProtectedRoute isLoggedIn={user.id}>
              <ProductItemView />
            </ProtectedRoute>
          }
        ></Route> */}
        <Route
          path="/contact"
          exact
          element={
            <ProtectedRoute isLoggedIn={user.id}>
              <ContactView />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/files"
          exact
          element={
            <ProtectedRoute isLoggedIn={user.id}>
              <FilesView />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/account"
          exact
          element={
            <ProtectedRoute isLoggedIn={user.id}>
              <AccountView />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/contact/:id"
          exact
          element={
            <ProtectedRoute isLoggedIn={user.id}>
              <ContactItemView />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/test"
          exact
          element={
            <ProtectedRoute isLoggedIn={user.id}>
              <TestView />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          path="/users"
          exact
          element={
            <AdminRoutes isAdmin={user.isAdmin}>
              <UsersView />
            </AdminRoutes>
          }
        ></Route>
        <Route
          path="/newUser"
          exact
          element={
            <AdminRoutes isAdmin={user.isAdmin}>
              <NewUserView />
            </AdminRoutes>
          }
        ></Route>
      </>

      {/* ---------------------------------- open Paths ------------------------------------- */}

      {!user.id || !token ? <Route path="/login" exact element={<LoginView />}></Route> : <Route path="/login" element={<Navigate to="/" />} />}
    </Switch>
  );
};

export default Routes;
