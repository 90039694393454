import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArticlesList from "../../Components/Articles/ArticlesList";
import Loading from "../../Components/Common/Loading";
import { getAllArticles } from "../../REDUX/actions/articles";

const BlogView = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getPageByName("Page Blog"));
    dispatch(getAllArticles());
    // eslint-disable-next-line
  }, []);

  // const { pagesLoading, ActivePage } = useSelector((state) => state.pagesReducer);
  const { articles } = useSelector((state) => state.articlesReducer);

  return !articles ? (
    <Loading />
  ) : (
    <>
      <ArticlesList />
    </>
  );
};

export default BlogView;
