import { Link } from "react-router-dom";
import ENDPOINT from "../REDUX/CONSTS/ENDPOINT.js";

export const otherLanguage = (lang) => {
  if (lang === "fr") {
    return "en";
  } else {
    return "fr";
  }
};

export const bgImage = (image) => {
  return !image.url
    ? `url(${ENDPOINT}${image.url ? image?.url : image.attributes?.url})`
    : null;
};

export const imageSrc = (image) => {
  return `${ENDPOINT}${image}`;
};

export const BreadCrumb = (company, path, whichType) => {
  const pathArray = path.split("/");

  switch (pathArray[1]) {
    case "":
      return (
        <span>
          <span className='text-grey'>{company}</span> / Dashboard
        </span>
      );
    case "home":
      return (
        <span>
          <span className='text-grey'>{company}</span> / Page Home
        </span>
      );
    case "blog":
      if (pathArray[2]) {
        return (
          <span>
            <span className='text-grey'>{company}</span> /{" "}
            <Link to={"/" + pathArray[1]}>Blog</Link> / {whichType}{" "}
          </span>
        );
      } else {
        return (
          <span>
            <span className='text-grey'>{company}</span> / Blog
          </span>
        );
      }
    case "events":
      return (
        <span>
          <span className='text-grey'>{company}</span> / Evenements
        </span>
      );
    case "event":
      if (pathArray[2]) {
        return (
          <span>
            <span className='text-grey'>{company}</span> /{" "}
            <Link to={"/" + pathArray[1] + "s"}>Evenement</Link> / {whichType}{" "}
          </span>
        );
      } else {
        return (
          <span>
            <span className='text-grey'>{company}</span> / Evenement
          </span>
        );
      }
    case "pages":
      if (pathArray[2]) {
        return (
          <span>
            <span className='text-grey'>{company}</span> /{" "}
            <Link to={"/" + pathArray[1]}>Pages</Link> / {whichType}{" "}
          </span>
        );
      } else {
        return (
          <span>
            <span className='text-grey'>{company}</span> / Pages
          </span>
        );
      }

    case "products":
      if (pathArray[2]) {
        return (
          <span>
            <span className='text-grey'>{company}</span> /{" "}
            <Link to={"/" + pathArray[1]}>Produits</Link> / {whichType}{" "}
          </span>
        );
      } else {
        return (
          <span>
            <span className='text-grey'>{company}</span> / Produits
          </span>
        );
      }
    case "showroom":
      if (pathArray[2]) {
        return (
          <span>
            <span className='text-grey'>{company}</span> /{" "}
            <Link to={"/" + pathArray[1]}>Revendeur</Link> / {whichType}{" "}
          </span>
        );
      } else {
        return (
          <span>
            <span className='text-grey'>{company}</span> / Revendeur
          </span>
        );
      }
    case "contact":
      if (pathArray[2]) {
        return (
          <span>
            <span className='text-grey'>{company}</span> /{" "}
            <Link to={"/" + pathArray[1]}>Contacts</Link> / {whichType}{" "}
          </span>
        );
      } else {
        return (
          <span>
            <span className='text-grey'>{company}</span> / Contacts
          </span>
        );
      }
    case "files":
      return (
        <span>
          <span className='text-grey'>{company}</span> / Fichiers
        </span>
      );
    case "account":
      return (
        <span>
          <span className='text-grey'>{company}</span> / Mon Compte
        </span>
      );

    default:
      break;
  }
};

export const pageLink = (pageName) => {
  switch (pageName) {
    case "Page d'acceuil":
      return "/";
    case "Page Cabinet":
      return "/cabinet";
    case "Page Syndic":
      return "/syndic";
    case "Page Gérance Location":
      return "/gerance-location";
    case "Page Transactions":
      return "/transaction";
    case "Page Actualités":
      return "/news";
    case "Page Nous contactez":
      return "/contact";
    default:
      return "/";
  }
};

export const productUrlRewrite = (id, name) => {
  return (
    id +
    "-" +
    name
      .replace(/ - /g, "-")
      .replace(/ & /g, "-")
      .replace(/ /g, "-")
      .replace(/\//g, "-")
      .replace(/é|è|ê|ë/g, "e")
      .replace(/à|ã|â|ä/g, "a")
      .replace(/ï|î|ì/g, "i")
      .replace(/ò|ô|ö|õ/g, "o")
      .replace(/û|ù|ü/g, "u")
      .replace(/ÿ/g, "y")
      .toLowerCase()
  );
};

export const interested = (code) => {
  switch (code) {
    case "0":
      return "Spa";
    case "1":
      return "Spa de nage";
    case "2":
      return "Sauna";

    default:
      return "Spa";
  }
};

export const contactType = (contact) => {
  switch (contact.attributes.serviceType) {
    case 0:
      return { color: "#A5F1E9", text: "Transaction" };
    case 1:
      return { color: "#E977774d", text: "Gérance Location" };
    case 2:
      return { color: "#344d674d", text: "Syndic" };
    case 3:
      return { color: "#F2E5E5", text: "Presse" };
    case 4:
      return { color: "#F1D3B3", text: "Partenariat" };
    case 5:
      return { color: "#CFF5E74d", text: "Candidature" };
    case 6:
      return { color: "#B6E2A14d", text: "Gestion du bien" };
    case 7:
      return { color: "#80489C4d", text: "Estimation Bien" };
    case 8:
      return { color: "#ecf4fb", text: "Offre Contact" };
    default:
      return { color: "", text: "" };
  }
};

export const sortFunction = (arr, champs, asc) => {
  return arr.sort((a, b) =>
    champs === "id"
      ? asc
        ? a.id?.localeCompare(b.id, "fr", { numeric: true })
        : b.id?.localeCompare(a.id, "fr", { numeric: true })
      : asc
      ? a.attributes[champs]
          ?.toString()
          ?.localeCompare(b.attributes[champs]?.toString(), "fr", {
            numeric: true,
          })
      : b.attributes[champs]
          ?.toString()
          ?.localeCompare(a.attributes[champs]?.toString(), "fr", {
            numeric: true,
          })
  );
};
