import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ArticleEventForm from "../../Components/ArticleEvent/ArticleEventForm";
import SectionTopPanel from "../../Components/ArticleEvent/SectionTopPanel";
import Loading from "../../Components/Common/Loading";
import SEOInputs from "../../Components/PageCard/SEOInputs";
import SectionInput from "../../Components/sectioninput/SectionInput";
import { getArticleItem } from "../../REDUX/actions/articles";

const ArticleItemView = () => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(getArticleItem(params.id));
    // eslint-disable-next-line
  }, []);

  const { articlesLoading, articleItem } = useSelector((state) => state.articlesReducer);

  return articlesLoading || !articleItem ? (
    <Loading />
  ) : (
    <div>
      <div className="row justify-content-center align-items-start">
        <div className=" col-12 col-md-12">
          <Accordion className=" rounded-10 ">
            <SEOInputs seo={articleItem.attributes.seo.data} title={articleItem.attributes.title} collapsed />
            <div className="my-2"></div>
            <SectionInput sectionData={articleItem.attributes.banner.data.attributes} sectionId={articleItem.attributes.banner.data.id} titleBlack="Bannière de l'article" />
            <div className="my-2"></div>
            <SectionTopPanel eventItemForm={articleItem} />
          </Accordion>
        </div>
      </div>

      <ArticleEventForm eventItemForm={articleItem} type="articles" />
    </div>
  );
};

export default ArticleItemView;
