import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../REDUX/actions/confirmationModal";
import { deleteImage, renamefile } from "../../REDUX/actions/files";
import { imageSrc } from "../../utils/utils";
import "./FileCard.css";

const FileCard = ({ file, name, selected, setselected, fileId }) => {
  const [isVideo, setisVideo] = useState(false);
  const [isDocument, setisDocument] = useState(false);
  const [renaming, setrenaming] = useState(false);
  const [newName, setnewName] = useState(name);
  const dispatch = useDispatch();

  useEffect(() => {
    if (file.attributes.mime.includes("video")) {
      setisVideo(true);
    } else if (file.attributes.mime.includes("application")) {
      setisDocument(true);
      setisVideo(false);
    } else {
      setisVideo(false);
    } // eslint-disable-next-line
  }, []);

  const handleMultipleSelect = (fileId) => {
    if (selected.indexOf(fileId) === -1) {
      setselected([...selected, fileId]);
    } else {
      setselected(selected.filter((el) => el !== fileId));
    }
  };
  const handleDelete = () => {
    dispatch(deleteImage(file.id, fileId));
  };
  const handleRenameFile = () => {
    dispatch(renamefile(fileId, newName));
    setrenaming(false);
  };

  const [deleteMessage] = useState(
    file.attributes.related.data.length > 1
      ? `<h3> <i className="bi bi-exclamation-octagon warnIcon"></i> Non Recommendé </h3> Êtes-vous sûr(e) de vouloir supprimer l'élément ?
        </br> l'élément est utilisé sur <bold> ${file.attributes.related.data.length - 1}  element(s)</bold> 
        </br> <div className="info"> Ceci peut entrainé des erreurs ou des problémes d'affichage sur votre site </div>`
      : `Êtes-vous sûr(e) de vouloir supprimer l'élément ? </br> l'élément n'est pas utilisé`
  );
  const showModal = async () => {
    dispatch(openModal({ type: "delete", confirmButtonText: "Supprimer", cancelButton: true, message: deleteMessage, callBack: handleDelete }));
  };
  return (
    <div
      className={`fileCard d-flex justify-content-center align-items-center ${selected.indexOf(fileId) !== -1 ? "fileSelected" : ""}`}
      onMouseLeave={() => {
        setrenaming(false);
        setnewName(name);
      }}
    >
      <div className="fileCardDeleteIcon" onClick={showModal}>
        <i className="bi f30 bi-trash3"></i>
      </div>
      <div className="fileCardSelectIcon" onClick={() => handleMultipleSelect(fileId)}>
        {selected.indexOf(fileId) !== -1 ? <i className="bi f26 bi-check2-square"></i> : <i className="bi f26 bi-square"></i>}
      </div>
      <div
        className="fileCardRename"
        onClick={() => {
          if (renaming) {
            handleRenameFile();
          } else {
            setrenaming(true);
          }
        }}
      >
        {renaming ? <i className="bi f30 bi-check-lg text-blue"></i> : <i className="bi f30 bi-pencil text-blue"></i>}
      </div>
      <div className="fileCardFilter"></div>
      {isDocument ? (
        <i className="bi f60 bi-file-earmark-richtext-fill"></i>
      ) : isVideo ? (
        <video controls width="100%">
          <source src={imageSrc(file.attributes.url)} type="video/mp4" />

          <source src={imageSrc(file.attributes.url)} type="video/webm" />
        </video>
      ) : (
        <img className="fileCardImage" src={imageSrc(file.attributes.formats?.thumbnail?.url || file.attributes.url)} alt={imageSrc(file.attributes.formats?.url)} />
      )}

      <div className="fileCardImageName d-flex align-items-center justify-content-center w-80 mx-auto ">
        {renaming ? (
          <input type="text" className="renameFileField ps-1 f14" value={newName} onChange={(e) => setnewName(e.target.value)} />
        ) : (
          <div>
            {name}
            {file.attributes.ext}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileCard;
