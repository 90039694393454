import React, { useEffect } from "react";
import "./Header.css";
import { useDispatch, useSelector } from "react-redux";

import { Link, useLocation } from "react-router-dom";
import { BreadCrumb } from "../../../utils/utils.js";
import LoadingHeader from "../../Common/LoadingHeader";
import { checkNewContact } from "../../../REDUX/actions/contact";

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.usersReducer);
  const { newContact, contactItem } = useSelector((state) => state.contactsReducer);
  const { articleItem } = useSelector((state) => state.articlesReducer);
  const { productItem } = useSelector((state) => state.productsReducer);
  const { ActivePage } = useSelector((state) => state.pagesReducer);

  function whichType() {
    const path = location.pathname;

    const pathArrayType = path.split("/");

    switch (pathArrayType[1]) {
      case "blog":
        return articleItem?.attributes.title;
      case "products":
        return productItem?.attributes.name;
      case "contact":
        return contactItem?.attributes.lastName;
      case "pages":
        return ActivePage?.attributes.title;
      default:
        break;
    }
  }

  useEffect(() => {
    dispatch(checkNewContact());
    // eslint-disable-next-line
  }, []);

  return !user.id ? (
    <LoadingHeader />
  ) : (
    <div className="container-fluid">
      <div className="headerContainer bg-white row align-items-center">
        <div className="col-6 text-start p-5 pt-0 pb-0">{location.pathname ? BreadCrumb("Taboni", location.pathname, whichType()) : null}</div>
        <div className="col-4 d-flex align-items-center justify-content-center gap-3">
          {/*  <i className="bi bi-search"></i>
          <input className="searchInput f12" type="text" placeholder="Votre recherche" />*/}
        </div>

        <div className="col-2 d-flex align-items-center justify-content-end gap-4 ">
          <Link to={"contact"}>
            <i className="bi bi-bell-fill bellIcon">{newContact && <div className="notifIn"></div>}</i>
          </Link>

          <div className=" d-flex align-items-center">
            <div className="languageFlag usrdefault"></div>
            <div className="text-start ms-2">
              <div className="text-capitalize">
                {user.firstName} {user.lastName}
              </div>
              <div className="text-grey f12">{user.isAdmin ? "Administrateur" : "Redacteur"}</div>
            </div>
          </div>

          {/* <i className="bi bi-chevron-down"></i> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
