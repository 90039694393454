import axios from "axios";
import ENDPOINT from "../REDUX/CONSTS/ENDPOINT.js";
const token = localStorage.getItem("token");

const API = axios.create({
  baseURL: ENDPOINT,
});
API.defaults.headers.common["Authorization"] = token;

export default API;
