import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../REDUX/actions/confirmationModal";
import { deleteImage } from "../../REDUX/actions/files";
import { imageSrc } from "../../utils/utils";
import "./FileCard.css";
import "./FileCardSelector.css";

const FileCardSelector = ({ file, name, handleImage, selected, setselected }) => {
  const dispatch = useDispatch();
  const [isVideo, setisVideo] = useState(false);
  const [isDocument, setisDocument] = useState(false);

  const handleDelete = () => {
    dispatch(deleteImage(file.id));
  };
  useEffect(() => {
    if (file.attributes.mime.includes("video")) {
      setisVideo(true);
    } else if (file.attributes.mime.includes("application")) {
      setisDocument(true);
      setisVideo(false);
    } else {
      setisVideo(false);
    } // eslint-disable-next-line
  }, []);
  const [deleteMessage] = useState(
    file.attributes.related
      ? `<h3> <i className="bi bi-exclamation-octagon warnIcon"></i> Non Recommendé </h3> Êtes-vous sûr(e) de vouloir supprimer l'élément ?
        </br> l'élément est utilisé sur <bold> ${file.attributes.related.length}  element(s)</bold> 
        </br> <div className="info"> Ceci peut entrainé des erreurs ou des problémes d'affichage sur votre site </div>`
      : `Êtes-vous sûr(e) de vouloir supprimer l'élément ? </br> l'élément n'est pas utilisé`
  );
  const showModal = async () => {
    dispatch(openModal({ type: "delete", confirmButtonText: "Supprimer", cancelButton: true, message: deleteMessage, callBack: handleDelete }));
  };
  return (
    <div
      className="fileCard d-flex justify-content-center align-items-center"
      onClick={() => {
        handleImage({ attributes: file.attributes, id: file.id });
        setselected(file.id);
      }}
    >
      <div className="fileCardDeleteIcon" onClick={showModal}>
        <i className="bi f30 bi-trash3"></i>
      </div>
      <div className="fileCardSelectIcon">{selected === file.id ? <i className="bi f26 bi-check2-square"></i> : <i className="bi f26 bi-square"></i>}</div>
      <div className="fileCardFilter"></div>
      {isDocument ? (
        <i className="bi f60 bi-file-earmark-richtext-fill"></i>
      ) : isVideo ? (
        <video controls width="100%">
          <source src={imageSrc(file.attributes.url)} type="video/mp4" />

          <source src={imageSrc(file.attributes.url)} type="video/webm" />
        </video>
      ) : (
        <img className="fileCardImage" src={imageSrc(file.attributes.formats?.thumbnail?.url || file.attributes.url)} alt={imageSrc(file.attributes.formats?.url)} />
      )}
      <div className="fileCardImageName w-80 m-a">{name}</div>
    </div>
  );
};

export default FileCardSelector;
