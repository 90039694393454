import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../REDUX/actions/confirmationModal";
import {
  getDossiers,
  getAllFiles,
  newFolder,
  renameFolder,
  moveFiles,
  deleteFolder,
} from "../../REDUX/actions/files";
import CustomButton from "../Common/CustomButton";
import Loading from "../Common/Loading";
import FileCard from "./FileCard";
import FileCardSelector from "./FileCardselector";
import "./FileList.css";

const FileList = ({ type, handleImage, image }) => {
  const dispatch = useDispatch();

  const { filesLoading, files, refetching, dossiers } = useSelector(
    (state) => state.filesReducer
  );

  const [counter, setcounter] = useState(0);
  const [selectedFolder, setSelectedFolder] = useState({});
  const [multipleSelected, setmultipleSelected] = useState([]);
  const [selected, setselected] = useState(null);
  const [newFolderName, setnewFolderName] = useState("");
  const [renamingName, setrenamingName] = useState("");
  const [allowRename, setallowRename] = useState(false);
  const [allowNewFolder, setallowNewFolder] = useState(false);
  const [destination, setdestination] = useState(dossiers[0]?.id);
  const [moving, setmoving] = useState(false);

  useEffect(() => {
    if (!files) {
      dispatch(getAllFiles(selectedFolder.id));
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getAllFiles(selectedFolder.id));
    // eslint-disable-next-line
  }, [selectedFolder.id]);

  useEffect(() => {
    dispatch(getDossiers());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (counter !== 0) {
      dispatch(getAllFiles(selectedFolder.id, counter, true));
    } // eslint-disable-next-line
  }, [counter]);

  useEffect(() => {
    if (allowRename) {
      dispatch(renameFolder(selectedFolder.id, renamingName));
      dispatch(closeModal());
      setallowRename(false);
    }
    if (allowNewFolder) {
      dispatch(newFolder(newFolderName));
      dispatch(closeModal());
      setallowNewFolder(false);
    } // eslint-disable-next-line
  }, [allowRename, allowNewFolder]);

  const increase = () => {
    setcounter(counter + 20);
  };

  const newFolderModal = async () => {
    dispatch(
      openModal({
        confirmButtonText: "Créer",
        cancelButton: true,
        message: "veuillez taper le nom du nouveau dossier",
        component: (
          <input
            type={"text"}
            onChange={(e) => {
              setnewFolderName(e.target.value);
            }}
            className={`styleInput w-100 ps-3 rounded-3`}
          />
        ),
        callBack: () => setallowNewFolder(true),
      })
    );
  };

  const renameFolderModal = async () => {
    dispatch(
      openModal({
        confirmButtonText: "Modifier",
        cancelButton: true,
        message: "veuillez taper le nom du dossier",
        component: (
          <input
            type={"text"}
            onChange={(e) => {
              setrenamingName(e.target.value);
            }}
            className={`styleInput w-100 ps-3 rounded-3`}
          />
        ),
        callBack: () => setallowRename(true),
      })
    );
  };

  const deleteFolderModal = async () => {
    dispatch(
      openModal({
        confirmButtonText: "Supprimer",
        cancelButton: true,
        message: "Etes-vous sûr de vouloir supprimer le dossier",
        component: (
          <span className="f12">
            Tous les fichiers seront déplacés vers le dossier principal
          </span>
        ),
        type: "delete",

        callBack: () => {
          dispatch(deleteFolder(selectedFolder.id));
          setSelectedFolder({});
        },
      })
    );
  };

  const handleMoveFiles = () => {
    dispatch(
      moveFiles(
        destination || dossiers[0].id,
        multipleSelected,
        dossiers,
        selectedFolder.id
      )
    );
    setmultipleSelected([]);
  };

  return filesLoading || !files ? (
    <Loading />
  ) : (
    <>
      <div className="d-flex text-capitalize justify-content-start px-5 gap-5 fileFilterContainer">
        <div
          className={`filesFilter ${
            !selectedFolder.id ? "filesFilterSelected" : ""
          }`}
          onClick={async () => {
            setSelectedFolder({});
            setmultipleSelected([]);
          }}
        >
          /
        </div>

        {dossiers.map((dossier, index) => (
          <div
            className={`filesFilter ${
              selectedFolder.id === dossier.id ? "filesFilterSelected" : ""
            }`}
            key={index}
            onClick={async () => {
              setSelectedFolder({ id: dossier.id });
              setmultipleSelected([]);
              setcounter(0);
            }}
          >
            /{dossier.attributes.name}
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center gap-4 mt-4">
        <div className="w-20">
          <CustomButton
            iconStart={"plus-lg"}
            buttonText={"Nouveau dossier"}
            callBack={newFolderModal}
          />
        </div>

        {selectedFolder.id ? (
          <div className="w-20">
            <CustomButton
              buttonText={"Renommer le dossier"}
              callBack={renameFolderModal}
            />
          </div>
        ) : null}
        {selectedFolder.id ? (
          <div className="w-20">
            <CustomButton
              buttonText={"Supprimer le dossier"}
              callBack={deleteFolderModal}
              type="red"
            />
          </div>
        ) : null}
        {multipleSelected.length !== 0 ? (
          <div className="w-20">
            <CustomButton
              iconStart={"move-lg"}
              buttonText={moving ? "Déplacer la selection" : "Annuler"}
              type="orange"
              callBack={() => {
                setmoving(!moving);
                if (!moving) {
                  setmultipleSelected([]);
                }
              }}
            />
            {!moving ? (
              <>
                <label className="f16 text-start w-100 mt-3">
                  Déplacer vers{" "}
                </label>

                <Form.Select onChange={(e) => setdestination(e.target.value)}>
                  <option value={null}>/</option>
                  {dossiers.map((el, index) => (
                    <option key={index} value={el.id}>
                      {el.attributes.name}
                    </option>
                  ))}
                </Form.Select>
                <div className="mt-4">
                  <CustomButton
                    buttonText={"Déplacer"}
                    callBack={() => handleMoveFiles()}
                  />
                </div>
              </>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="fileListContainer d-flex justify-content-center flex-wrap pt-4">
        {files.map((file, index) =>
          type === "select" ? (
            <FileCardSelector
              image={image}
              handleImage={handleImage}
              selected={selected}
              setselected={setselected}
              file={file.attributes.media.data}
              name={file.attributes.name}
              key={index}
              fileId={file.id}
            />
          ) : (
            <FileCard
              file={file.attributes.media.data}
              selected={multipleSelected}
              setselected={setmultipleSelected}
              name={file.attributes.name}
              key={index}
              fileId={file.id}
            />
          )
        )}
      </div>
      {refetching ? (
        <Loading />
      ) : (
        <div className="w-40 m-auto mt-4">
          <CustomButton
            buttonText={"Plus d'images ..."}
            callBack={increase}
          ></CustomButton>
        </div>
      )}
    </>
  );
};

export default FileList;
