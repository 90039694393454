import API from "../../Api/Api.js";
import { productUrlRewrite } from "../../utils/utils.js";
import { closeModal } from "./confirmationModal.js";
import { handleError, handleInfo } from "./notifications.js";
import qs from "qs";
const token = localStorage.getItem("token");

export const getAllProducts = (filter) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  const query = qs.stringify(
    {
      filters: {
        name: {
          $containsi: filter,
        },
      },
      populate: {
        image: {
          populate: {
            url: "*",
          },
        },
      },
      sort: ["createdAt:desc"],
    },

    {
      encodeValuesOnly: true,
    }
  );

  dispatch({ type: "PRODUCTS_LOADING" });
  try {
    let { data } = await API.get("api/products?" + query);
    dispatch({ type: "FETCH_PRODUCTS", payload: data.data });
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const deleteProduct = (id) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  dispatch({ type: "PRODUCTS_LOADING" });
  try {
    await API.delete(`api/products/${id}`);
    dispatch(getAllProducts());
    dispatch(handleInfo("Produit supprimé", 1));
    dispatch(closeModal());
  } catch (error) {
    dispatch(handleError("Erreur lors de la suppression du produit", 1));
    console.log(error);
  }
};

export const getProductItem = (id, noLoading) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  const query = qs.stringify(
    {
      filters: { id: { $eq: id } },
      populate: {
        banner: {
          populate: "*",
        },
        image: {
          populate: {
            url: "*",
          },
        },
        image2: {
          populate: {
            url: "*",
          },
        },
        ficheTechnique: {
          populate: {
            url: "*",
          },
        },
        seo: {
          populate: "ogimage",
        },
      },
    },
    { encodeValuesOnly: true }
  );

  if (!noLoading) {
    dispatch({ type: "PRODUCTS_LOADING" });
  }

  try {
    let { data } = await API.get("/api/products?" + query);

    dispatch({ type: "FETCH_PRODUCT_ITEM", payload: data.data[0] });
    dispatch({ type: "LIST_DONE_LOADING" });
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

const createList = async (defaultObject, name, resolution) => {
  let cardFR = await API.post("/api/cards", { data: defaultObject });

  let defaultListFR = {
    name: `Liste ${name} produit`,
    resolution: resolution,

    cards: [cardFR.data.data.id],
  };

  let listFR = await API.post("/api/lists", { data: defaultListFR });

  return { listFR };
};

export const addProduct = () => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  API.defaults.params = {};
  let defaultImage = null;

  dispatch({ type: "PRODUCTS_LOADING" });
  try {
    let image = await API.get("api/upload/files");
    defaultImage = image.data[0].id;
  } catch (error) {
    dispatch(handleError("Erreur reseau", 1));

    console.log(error);
  }

  try {
    if (!defaultImage) {
      return dispatch(
        handleError("Importer au moin une image pour procéder", 1)
      );
    }
    let defaultSEOFR = {
      title: "titre SEO produit par defaut",
      description: "SEO description produit par defaut",
      ogimage: defaultImage,
    };

    const defaultBanner = {
      name: "Banniére produit ",
      title: "banniere par default",
      subtitle: "soustitre par default",
      image: [defaultImage],
    };

    let SEO = await API.post("/api/seos", { data: defaultSEOFR });

    let banner = await API.post("/api/sections", { data: defaultBanner });

    let defaultProductFr = {
      name: "Produit par défaut",
      type: 0,
      collection: 0,
      places: 1,
      onstock: 0,
      image: defaultImage,
      price: 10000,
      seo: SEO.data.data.id,
      banner: banner.data.data.id,
    };

    const productFr = await API.post("/api/products", {
      data: defaultProductFr,
    });

    await API.put(`/api/products/${productFr.data.data.id}`, {
      data: {
        path: productUrlRewrite(
          productFr.data.data.id,
          productFr.data.data.attributes.name
        ),
      },
    });

    dispatch(
      handleInfo("Produit par défaut Ajouté (Vous pouvez le modifier) ", 1)
    );
    dispatch(getAllProducts());
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};

export const updateProduct = (id, product) => async (dispatch) => {
  API.defaults.headers.common["Authorization"] = token;

  dispatch({ type: "PRODUCTS_LOADING" });
  try {
    await API.put(`/api/products/${id}`, { data: product });
    dispatch(handleInfo("Produit Modifié", 1));
    dispatch(getProductItem(id));
  } catch (error) {
    dispatch(handleError("erreur réseau", 1));
    console.log(error);
  }
};
