const initialState = {
  list: null,
  listLoading: false,
  independantList: null,
  jetCard: null,
};
export const listsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "LIST_LOADING":
      return { ...state, listLoading: true };
    case "FETCH_LIST":
      return { ...state, listLoading: false, list: payload };
    case "INDEPENDANT_LIST":
      return { ...state, listLoading: false, independantList: payload };
    case "GET_JET_CARD":
      return { ...state, listLoading: false, jetCard: payload };
    case "LIST_DONE_LOADING":
      return { ...state, listLoading: false };
    default:
      return state;
  }
};
