import { combineReducers } from "redux";
import { filesReducer } from "./files.js";
import { pagesReducer } from "./pages.js";
import { notificationsReducer } from "./notifications.js";
import { modalReducer } from "./modal.js";
import { productsReducer } from "./products.js";
import { articlesReducer } from "./articles.js";
import { usersReducer } from "./users.js";
import { contactsReducer } from "./contact.js";
import { listsReducer } from "./lists.js";
import { statsReducer } from "./stats.js";

const rootReducer = combineReducers({
  pagesReducer,
  notificationsReducer,
  filesReducer,
  modalReducer,
  productsReducer,
  articlesReducer,
  usersReducer,
  contactsReducer,
  listsReducer,
  statsReducer,
});
export default rootReducer;
