import React, { useState } from "react";
import { useDispatch } from "react-redux";

import FileList from "../../Components/Files/FileList";
import { addImages } from "../../REDUX/actions/files";

const FilesModalSelector = ({ handleImage, image }) => {
  const dispatch = useDispatch();

  const [imagesUploaded, setimagesUploaded] = useState({});
  const addImage = (event) => {
    setimagesUploaded({
      images: event.target.files,
    });
  };
  const uploadFiles = async (e) => {
    let imageForm = new FormData();
    e.preventDefault();

    Array.from(imagesUploaded.images).forEach((image) => imageForm.append("files", image));
    await dispatch(addImages(imageForm));
    setimagesUploaded({});
    imageForm.delete("images");
  };

  return (
    <div className="bg-white py-4 rounded-10">
      <form className="d-flex flex-column align-items-center mb-4" onSubmit={uploadFiles}>
        <input type="file" multiple name="files" onChange={addImage} />
        <button type="submit" className="btn btn-primary  text-white mt-3 w-70 rounded-10" disabled={!imagesUploaded.images}>
          Upload
        </button>
      </form>

      <FileList image={image} type="select" handleImage={handleImage} />
    </div>
  );
};

export default FilesModalSelector;
