import qs from "qs";

export const pageQueryOnlyLocale = qs.stringify(
  {
    sort: ["name:asc"],
    populate: {},
  },

  {
    encodeValuesOnly: true,
  }
);
export const pageQueryAll = qs.stringify(
  {
    sort: ["name:asc"],
    populate: {
      sections: {
        populate: {
          image: {
            populate: "*",
          },
          image2: { populate: ["*"] },

          list: {
            populate: {
              cards: { populate: ["image", "image2"], sort: ["order:asc"] },
            },
          },
        },

        sort: ["order:asc"],
      },

      seo: {
        populate: "ogimage",
      },
    },
  },

  {
    encodeValuesOnly: true,
  }
);
