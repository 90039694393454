const initialState = {
  dbStats: null,
  statsLoading: false,
};

export const statsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_DB_STATS":
      return { ...state, dbStats: payload, statsLoading: false };
    case "STATS_LOADING":
      return { ...state, statsLoading: true };

    default:
      return state;
  }
};
