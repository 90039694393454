import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteCard, updateCard } from "../../REDUX/actions/lists";
import CustomButton from "../Common/CustomButton";
import Uploader from "../Common/Uploader";
import InputText from "../sectioninput/formelement/InputText";
import _ from "lodash";
import { Form } from "react-bootstrap";
import CustomSelect from "../sectioninput/formelement/CustomSelect";
import LoadingButton from "../Common/LoadingButton";

const ListCard = ({ cardData, listId, noMoreDeletes, fetchProduct = null, resolution = "carré", listLoading, magasinId }) => {
  const dispatch = useDispatch();
  const [newImage, setnewImage] = useState(cardData.attributes.image.data);
  const [newImage2, setnewImage2] = useState(cardData.attributes.image2.data);
  const [buttonUrl, setbuttonUrl] = useState(cardData.attributes.buttonUrl);
  const [showButton, setshowButton] = useState(cardData.attributes.showButton);

  const [listInputs, setlistInputs] = useState(_.omit(cardData.attributes, ["locale", "image", "image2", "createdAt", "updatedAt", "list", "resolution"]));
  useEffect(() => {
    setlistInputs(_.omit(cardData.attributes, ["locale", "image", "image2", "createdAt", "updatedAt", "list", "resolution"]));
    setnewImage(cardData.attributes.image.data);
    setnewImage2(cardData.attributes.image2.data);
    setbuttonUrl(cardData.attributes.buttonUrl);
    setshowButton(cardData.attributes.showButton);
  }, [cardData]);

  const handleImage = (newImage) => {
    setnewImage(newImage);
  };
  const handleImage2 = (newImage) => {
    setnewImage2(newImage);
  };
  const handleChange = (value, champs) => {
    setlistInputs({ ...listInputs, [champs]: value });
  };
  const handleSubmit = () => {
    dispatch(updateCard({ ...listInputs, buttonUrl: buttonUrl, image: newImage?.id, image2: newImage2?.id }, cardData.id, listId, fetchProduct, magasinId));
  };

  const handleDelete = () => {
    dispatch(deleteCard(cardData.id, listId, fetchProduct, magasinId));
  };
  const handleButtonShow = (value) => {
    setshowButton(!showButton);
    setlistInputs({ ...listInputs, showButton: value });
  };
  const handleChangeButtonUrl = (value) => {
    setbuttonUrl(value);
  };
  return (
    <div className="container ">
      <div className="col-12 row justify-content-center mb-5">
        {newImage && (
          <div className="col-6">
            <Uploader image={newImage} handleImage={handleImage} resolution={resolution} />
          </div>
        )}
        {newImage2 && (
          <div className="col-6">
            <Uploader image={newImage2} handleImage={handleImage2} resolution="carré" />
          </div>
        )}
      </div>
      <div className=" col-12">
        <div className="row">
          {listInputs.title !== null ? (
            <div className="col-8">
              <InputText labelinput="Titre" value={listInputs.title} handleChange={handleChange} champs="title" />
            </div>
          ) : null}
          <div className="col-4">
            <InputText labelinput="Ordre d'affichage" type="number" value={listInputs.order} handleChange={handleChange} champs="order" />
          </div>
        </div>

        {listInputs.subtitle !== null ? <InputText labelinput="Sous-Titre" value={listInputs.subtitle} handleChange={handleChange} champs="subtitle" /> : null}

        <div className="row mt-3 align-items-start">
          <div className="col-2" key={listId}>
            <label className="f16 ">Afficher Bouton </label>
            <Form.Switch key={Math.random()} defaultChecked={listInputs.showButton} onChange={(e) => handleButtonShow(e.target.checked)}></Form.Switch>
          </div>
          {listInputs.showButton && (
            <div className="col">
              <CustomSelect handleChange={handleChangeButtonUrl} value={buttonUrl} />
            </div>
          )}
        </div>
        {listInputs.showButton && (
          <div className="row pt-2 pb-2">
            <div className="col">
              <InputText labelinput="Texte du bouton" value={listInputs.buttonText} handleChange={handleChange} champs="buttonText" />
            </div>
            <div className="col">
              <InputText labelinput="URL du bouton" value={buttonUrl} flag={false} readOnly champs="buttonUrl" />
            </div>
          </div>
        )}
        {listInputs.description !== null ? <InputText labelinput="Description" textArea value={listInputs.description} handleChange={handleChange} champs="description" /> : null}
        {listInputs.content !== null ? <InputText labelinput="Contenu" textArea value={listInputs.content} handleChange={handleChange} champs="content" /> : null}
      </div>
      <div className="row buttonsCustomPadding mt-2">
        <div className="col-8">
          <CustomButton disabled={listLoading} buttonText={listLoading ? <LoadingButton /> : "Enregistrer"} callBack={handleSubmit} iconStart={listLoading ? <LoadingButton /> : "pencil-fill"} />
        </div>
        <div className="col-4 ">
          <CustomButton
            buttonText={listLoading ? <LoadingButton /> : !noMoreDeletes ? "Supprimer la carte" : "Interdit de vider la liste"}
            type={"red"}
            iconStart={listLoading ? <LoadingButton /> : "trash-fill"}
            className="btn btn-primary col-12 text-white mt-3"
            disabled={noMoreDeletes || listLoading}
            callBack={handleDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default ListCard;
