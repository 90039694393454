import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllContacts } from "../../REDUX/actions/contact";
import { sortFunction } from "../../utils/utils";
import EmptyList from "../Common/EmptyList";
import Loading from "../Common/Loading";
import ContactCard from "./ContactCard";

const ContactList = () => {
  const dispatch = useDispatch();
  const [pageNumber, setpageNumber] = useState(1);
  const [sortContact, setsortContact] = useState({
    champs: "createdAt",
    asc: false,
  });
  useEffect(() => {
    dispatch(getAllContacts(pageNumber));
  }, [pageNumber]);

  const { contactLoading, contacts, pages } = useSelector(
    (state) => state.contactsReducer
  );

  return contactLoading || !contacts ? (
    <Loading />
  ) : (
    <div className='d-flex flex-column'>
      <div className='row f24 align-items-center'>Liste des messages</div>
      <div className='row  w-98 m-auto mt-5'>
        <div
          className='col-1 f16 bolder text-start pointer d-flex gap-2'
          onClick={() =>
            setsortContact({ champs: "createdAt", asc: !sortContact.asc })
          }
        >
          Date <i className='bi bi-sort-down-alt'></i>
        </div>
        <div
          className='col-1 f16 bolder text-start pointer d-flex gap-2'
          onClick={() =>
            setsortContact({ champs: "serviceType", asc: !sortContact.asc })
          }
        >
          Type <i className='bi bi-sort-down-alt'></i>
        </div>
        <div
          className='col-3 f16 bolder text-start pointer d-flex gap-2'
          onClick={() =>
            setsortContact({ champs: "email", asc: !sortContact.asc })
          }
        >
          E-mail <i className='bi bi-sort-down-alt'></i>
        </div>
        <div
          className='col-3 f16 bolder text-start pointer d-flex gap-2'
          onClick={() =>
            setsortContact({ champs: "lastName", asc: !sortContact.asc })
          }
        >
          Nom/Prénom <i className='bi bi-sort-down-alt'></i>
        </div>
        <div
          className='col-1 f16 bolder text-start pointer d-flex gap-2'
          onClick={() =>
            setsortContact({ champs: "viewedBy", asc: !sortContact.asc })
          }
        >
          Status <i className='bi bi-sort-down-alt'></i>
        </div>
        <div className='col-2 f16 bolder text-start'>Action</div>
      </div>
      <div className='mt-2'>
        {contacts.length ? (
          sortFunction(contacts, sortContact.champs, sortContact.asc).map(
            (contact, index) => (
              <ContactCard key={index} contact={contact} index={index} />
            )
          )
        ) : (
          <EmptyList text='Pas de nouveau messages' />
        )}
      </div>
      <div className='d-flex justify-content-end mt-4 gap-4 paginationList'>
        {[...Array(pages)].map((el, index) => (
          <div key={index}>
            <button
              className={`myBtn px-3 customButtonorange ${
                index + 1 === pageNumber ? "customButtonOutlinedorange" : ""
              }`}
              onClick={() => setpageNumber(index + 1)}
            >
              {index + 1}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactList;
