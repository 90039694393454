import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Components/Common/CustomButton";
import Loading from "../../Components/Common/Loading";
import InputText from "../../Components/sectioninput/formelement/InputText";
import { handleError } from "../../REDUX/actions/notifications";
import { updateCredentials, updateUser } from "../../REDUX/actions/users";

const AccountView = () => {
  const dispatch = useDispatch();

  const { userLoading, user } = useSelector((state) => state.usersReducer);

  const [formData, setuserForm] = useState(user);

  const [passwordConfirmation, setpasswordConfirmation] = useState("");
  const disableForm = !formData.username || !formData.lastName || !formData.firstName;
  const disableCredentials = !formData.email || !formData.newEmail || !formData.oldPassword || !formData.newPassword || !passwordConfirmation;

  useEffect(() => {
    setuserForm(user);
  }, [user]);

  const handleChangeForm = (value, champs) => {
    setuserForm({ ...formData, [champs]: value });
  };

  const handleChangePass = (value) => {
    setpasswordConfirmation(value);
  };

  const handleUpdateUser = () => {
    dispatch(updateUser(formData, user.id));
  };

  const handleUpdateCredentials = () => {
    if (passwordConfirmation !== formData.newPassword) {
      dispatch(handleError("Mot de passes différents", 1));
      // eslint-disable-next-line
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/.test(formData.newEmail)) {
      dispatch(handleError("Adresse email invalide", 1));
    } else {
      dispatch(updateCredentials(formData, user.id));
    }
  };

  return !formData || userLoading ? (
    <Loading />
  ) : (
    <div className="container-fluid">
      <div className="row justify-content-center gap-5">
        <div className="col-6 bg-white rounded-10 shadowed p-5">
          <h2 className="mb-0 f24 fw-normal text-start mb-5">Modifier vos infos</h2>
          <InputText labelinput="Utilisateur" handleChange={handleChangeForm} value={formData.username} champs="username" flag={false} />
          <InputText labelinput="Nom" handleChange={handleChangeForm} value={formData.lastName} champs="lastName" flag={false} />
          <InputText labelinput="Prénom" handleChange={handleChangeForm} value={formData.firstName} champs="firstName" flag={false} />
          <div className="mt-5">
            <CustomButton buttonText="Modifier votre profil" iconStart={"pencil"} disabled={disableForm} callBack={handleUpdateUser} />
          </div>
        </div>

        <div className="col-5 bg-white rounded-10 shadowed p-5">
          <h2 className="mb-0 f24 fw-normal text-start mb-5">Modifier vos données de connexion</h2>

          <InputText labelinput="Ancien E-mail" handleChange={handleChangeForm} value={formData.email} champs="email" flag={false} />
          <InputText labelinput="Ancien mot de passe" type="password" handleChange={handleChangeForm} champs="oldPassword" flag={false} />
          <InputText labelinput="Nouvel E-mail" handleChange={handleChangeForm} champs="newEmail" flag={false} />
          <InputText labelinput="Nouveau mot de passe" type="password" handleChange={handleChangeForm} champs="newPassword" flag={false} />
          <InputText labelinput="Confirmation du nouveau mot de passe" type="password" handleChange={handleChangePass} champs="password" flag={false} />
          <div className="mt-5">
            <CustomButton buttonText="Modifier vos données de connexion" iconStart={"pencil"} disabled={disableCredentials} callBack={handleUpdateCredentials} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountView;
